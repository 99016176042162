<template>
  <section id="try-it" style="background-color: #FFFFFF">
    <v-container>
      <v-layout row wrap align-center>
        <v-flex xs12 sm6 v-if="$vuetify.breakpoint.mdAndUp">
          <v-img max-height="70%" max-width="90%" contain src="/static/4Dr.png" alt />
        </v-flex>
        <v-flex xs12 sm6>
          <h1
            :style="$vuetify.breakpoint.mdAndUp ? 'font-family: sans-serif;font-size:55px' : 'font-family: sans-serif;font-size:55px;text-align:center'"
          >Contáctanos</h1>
          <p :style="$vuetify.breakpoint.mdAndUp ? '' : 'text-align:center'">
            Si quieres más información sobre el proyecto o saber cómo puedes ayudar
            <br />
            <br />No dudes en contactarnos
          </p>
          <v-text-field
            :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-left:15px;margin-right:15px;'"
            outlined
            dense
            label="Nombre"
            v-model="contact.name"
          ></v-text-field>
          <v-text-field
            :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-left:15px;margin-right:15px;'"
            outlined
            dense
            label="Correo"
            v-model="contact.mail"
          ></v-text-field>
          <v-text-field
            :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-left:15px;margin-right:15px;'"
            outlined
            dense
            label="Teléfono"
            v-model="contact.phone"
          ></v-text-field>
          <v-textarea
            :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-left:15px;margin-right:15px;'"
            outlined
            no-resize
            dense
            style="resize: none;"
            label="Comentario"
            v-model="contact.comment"
          ></v-textarea>
          <v-layout row>
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
            <v-btn class="enviar-btn" @click="sendMail()">Enviar</v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <div
      id="apps"
      style="margin-left: 40px; margin-right: 40px; z-index: 2;"
      :class="this.$vuetify.breakpoint.smAndUp ? 'map-desktop': 'map-mobile'"
    >
      <div style="width: 100%">
        <iframe
          width="100%"
          height="600"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.464189393276!2d-100.31374428460832!3d25.3557548314708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDIxJzIwLjciTiAxMDDCsDE4JzQxLjYiVw!5e0!3m2!1ses!2smx!4v1595349957168!5m2!1ses!2smx"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
      <br />
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    contact: {
      name: "",
      mail: "",
      phone: "",
      comment: "",
    },
    e1: 1,
    networks: [
      {
        img: "/static/ig_footer.png",
        url: "www.instagram.com",
      },

      {
        img: "/static/facebook_footer.png",
        url: "www.facebook.com",
      },
    ],
  }),
  methods: {
    sendMail() {
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
<style>
@media (max-width: 900px) and (min-width: 0px) {
  .enviar-btn {
    color: white !important;
    background-color: #2487dc !important;
    font-size: 12px !important;
    margin-left: 40%;
  }
}

@media (min-width: 900px) {
  .enviar-btn {
    color: white !important;
    background-color: #2487dc !important;
    font-size: 12px !important;
  }
}

.map-desktop {
  margin-bottom: 150px;
}

.map-mobile {
  margin-bottom: 330px;
}

.footer-net {
  max-width: 50px !important;
}
.quote_text {
  color: white;
  font-size: 20px;
  background-color: #f7a9f5;
  padding: 20px;
  font-style: italic;
}
.quote_text_mobile {
  color: white;
  font-size: 25px;
}
.quote_text_author {
  font-size: 24px;
  color: white;
  margin-top: -3%;
}
.pink_quote {
  width: 100%;
  border-radius: 0px !important;
  background-color: #f7a9f5 !important;
}
.video_position {
  margin-top: 270px;
  margin-left: -250px;
}
.video_position_mobile {
  margin: 10px;
}
.video_container {
  margin-top: -10%;
  height: 100%;
  width: 100%;
  min-width: 50vh;
  margin-left: -8vh;
  padding: 50px;
}
.video_container_mobile {
  max-width: 90%;
}
.video_mobile {
  margin: 10%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 9%;
  height: 0;
  overflow: hidden;
}
.margin-xl {
  margin-top: 400px !important;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 90%;
  height: 90%;
}
.pink-div-md {
  height: 60%;
  width: 60% !important;
}
.pink-div-sm {
  width: 100% !important;
}
.iframe-md {
  bottom: 20% !important;
  width: 70% !important;
  left: 30% !important;
}
.iframe-sm {
  width: 100% !important;
}
.plans-md {
  margin-top: 50px;
}
.rowcontainer-md {
}
.pink-div {
  background-color: #f7a9f5;
  color: white;
  padding: 20px;
  font-style: italic;
}
.stepper-style {
  padding: 10vh 5vw;
}
.my-stepper {
  padding: 10px;
  max-width: 80px;
}

.contact-img {
}

.contact-img-mobile {
}
</style>
